import React from 'react';
import { Link } from 'gatsby';
import Icon from 'icon';
import Img from 'img';
import getDate from 'utils/get-date';
import Headline from 'headline';
import Button from 'button';
import { navigate } from '@reach/router';

import s from './blog-post.module.scss';

export default function BlogPost(props) {
  const { mainImage: image, date, excerpt, slug, title, className } = props;
  return (
    <div
      className={`${s.root} ${className || ''}`}
      tabIndex="0"
      role="button"
      onClick={() => navigate(`/${slug}`)}
    >
      <div className={s.infoWrapper}>
        <div className={s.imageWrapper}>
          <Img src={image?.title} className={s.image} />
        </div>
        {/* <p className={s.date}>{getDate(date)}</p> */}
        <Headline h3 className={s.title} html={title.rendered} />
        <p
          className={`text-body-light ${s.excerpt}`}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </div>
      <div className={s.buttonWrapper}>
        <Button href={`/${slug}`} className={s.button} tertiary>
          READ MORE
        </Button>
      </div>
    </div>
  );
}
