import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';
import Img from 'img';
import s from './latest.module.scss';

export default function LatestPost({ latestPost, ...other }) {
  // data
  const postTitle = latestPost?.title?.rendered;
  const postSubtitle = latestPost?.content ? latestPost?.content?.rendered : '';
  const { excerpt, slug } = latestPost;
  // tags

  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <div className={`${s.imgWrapper}`}>
          {latestPost?.mainImage && (
            <Img
              className={s.image}
              src={latestPost.mainimage?.title}
              alt={latestPost.mainimage?.title}
            />
          )}
          <div className={`${s.titleWrapper}`}>
            <p
              className={`${s.title}`}
              dangerouslySetInnerHTML={{ __html: postTitle }}
            />
            {postSubtitle && (
              <p
                className={`${s.title} ${s.capitalized}`}
                dangerouslySetInnerHTML={{ __html: postSubtitle }}
              />
            )}
            <Button className={`${s.button}`} href={`/${slug}/`}>
              Read more
            </Button>
          </div>
        </div>
        <div className={`${s.textWrapper}`}>
          <div className={s.headlineWrapper}>
            <Headline h2 wild className={s.headline}>
              Our
            </Headline>
            <Headline h2 wild className={`${s.headline} ${s.line2}`}>
              Latest Post
            </Headline>
            <Headline h2 wildExtra className={`${s.underline}`}>
              D
            </Headline>
          </div>
          <p
            className={`${s.excerpt}`}
            dangerouslySetInnerHTML={{ __html: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.' }}
          />
        </div>
      </div>
    </Container>
  );
}
